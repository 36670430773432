import styled from "styled-components";
import { ReactComponent as MyLogo } from "../assets/undraw_code_thinking_re_gka2.svg";
import { ScoreCard } from "./ScoreCard";
interface SelectionProps {
  score: string;
}

export const Selection: React.FC<SelectionProps> = ({ score }) => {
  console.log(score);
  return (
    <SelectionLayout>
      {score && <ScoreCard score={score}></ScoreCard>}
      {!score && (
        <div>
          <Placeholder>
            <MyLogo />
          </Placeholder>
          <SelectText>Select an estimate below</SelectText>
        </div>
      )}
    </SelectionLayout>
  );
};
const SelectionLayout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
`;
const Placeholder = styled.div`
  width: 100%;
  height: 100%;
`;

const SelectText = styled.p`
  margin-top: 10px;
  font-size: 25px;
`;
