/* globals zoomSdk */
import { useEffect, useState } from "react";
import App from "./App";
import { useAuth } from "./hooks/useAuth";

function AppWrapper() {
  const [uuid, setUUID] = useState(null);
  const { user, login } = useAuth();

  useEffect(() => {
    const startup = async () => {
      await login();
    };
    startup();
  }, [login]);

  useEffect(() => {
    const initializeUUID = async () => {
      try {
        await zoomSdk.config({
          capabilities: [
            // apis demoed in the buttons
            "getMeetingUUID",

            // demo events
            "onSendAppInvitation",
            "onShareApp",
            "onActiveSpeakerChange",
            "onMeeting",

            // connect api and event
            "connect",
            "onConnect",
            "postMessage",
            "onMessage",

            // in-client api and event
            "authorize",
            "onAuthorized",
            "promptAuthorize",
            "getUserContext",
            "onMyUserContextChange",
            "sendAppInvitationToAllParticipants",
            "sendAppInvitation",
          ],
          version: "0.16.0",
        });
        const getMeetingUUID = await zoomSdk.getMeetingUUID();
        setUUID(getMeetingUUID["meetingUUID"]);
        window.uuid = getMeetingUUID["meetingUUID"];
      } catch (error) {
        //Zoom sdk not available in development mode, so fake the uuid
        setUUID("1234");
        window.uuid = "1234";
      }
    };

    initializeUUID();
  }, []);

  return (
    <div>{uuid && user && user.uid && <App user={user} login={login} />}</div>
  );
}

export default AppWrapper;
