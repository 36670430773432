import {signIn} from "../services/firebase";
import * as React from "react";
import {useCallback} from "react";

export interface IUser{
    uid: string,
    displayName: string
}
interface IAuthContext {
    user: IUser | null,
    login: ()=> Promise<string>
}

interface Props {
    children: React.ReactNode;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

const AuthProvider: React.FC<Props> = (props) => {
    const [user, setUser] = React.useState<IUser | null>(null);

    const login = useCallback(async () => {
        const user = await signIn() as IUser;
        setUser(user);
        return user.uid;
    }, []);


    return(
        <AuthContext.Provider value={{user, login}}>{props.children}</AuthContext.Provider>
    )
};

export {AuthContext, AuthProvider}