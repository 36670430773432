import styled from "styled-components";
const Name = ({ name, setName }) => {
  return (
    <div>
      <NameInput
        value={name}
        onChange={(e) => setName(e.target.value)}
      ></NameInput>
    </div>
  );
};

const NameInput = styled.input`
  color: #fff;
  background-color: rgba(255, 255, 255, 0.12);
  border: none;
  text-align: center;
  border-radius: 10px;
  font-size: 25px;
  margin-top: 10px;
`;

export default Name;
