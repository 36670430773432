// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, signInAnonymously } from "firebase/auth";
import {
  getDatabase,
  ref,
  set,
  onDisconnect,
  get,
  child,
} from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBAB8qLGmjNZ1AGFp5jvCo-FcfgvHnnur8",
  authDomain: "scrum-poker-zoom.firebaseapp.com",
  databaseURL: "https://scrum-poker-zoom-default-rtdb.firebaseio.com",
  projectId: "scrum-poker-zoom",
  storageBucket: "scrum-poker-zoom.appspot.com",
  messagingSenderId: "1017735568615",
  appId: "1:1017735568615:web:f9ae9aa4b94ec13a77d642",
  measurementId: "G-YW6MX8KVFG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log("firebase");
const database = getDatabase(app);

async function signIn() {
  try {
    const auth = getAuth();
    const { user } = await signInAnonymously(auth);

    //Get users current name and set to displayName
    let r = ref(database, `meeting/${window.uuid}/users/${user.uid}`);
    // get(child(database, `meeting/${window.uuid}/user/${user.uid}`)).then(
    //   (s) => {
    //     console.log(s.val());
    //   }
    // );
    await onDisconnect(r).remove();

    console.log(window.uuid);
    // if (data.exists() && data.val().displayName) {
    //   return { uid: user.uid, displayName: data.val().displayName };
    // }
    return { uid: user.uid, displayName: "" };
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.error(errorCode + " " + errorMessage);
  }
}

function writeScore(score, uid) {
  if (!uid) return;
  return set(ref(database, `meeting/${window.uuid}/users/${uid}/score`), score)
    .then((f) => {
      console.log("Write done");
    })
    .catch((err) => {
      console.log(err);
    });
}

function toggleDisplay(valueToSet) {
  return set(
    ref(database, `meeting/${window.uuid}/settings/display`),
    valueToSet
  )
    .then((f) => {
      console.log("Write done");
    })
    .catch((err) => {
      console.log(err);
    });
}

function updateName(uid, display) {
  if (!uid) return;
  return set(
    ref(database, `meeting/${window.uuid}/users/${uid}/display`),
    display
  )
    .then((f) => {
      console.log("writing name ", display);
    })
    .catch((err) => {
      console.log(err);
    });
}

function observeScores() {
  return ref(database, `meeting/${window.uuid}/users`);
}

function observeSettings() {
  return ref(database, `meeting/${window.uuid}/settings/display`);
}

function clearScores() {
  const dbRef = ref(database);
  get(child(dbRef, `meeting/${window.uuid}/users`)).then((snapshot) => {
    if (snapshot.exists()) {
      console.log(snapshot.val());

      const data = snapshot.val();
      for (const prop in data) {
        if (data[prop].score) {
          delete data[prop].score;
        }
      }

      set(ref(database, `meeting/${window.uuid}/users`), data);
    } else {
      console.log("No data available");
    }
  });
}

export {
  signIn,
  writeScore,
  observeScores,
  clearScores,
  updateName,
  toggleDisplay,
  observeSettings,
};
