import styled from "styled-components"

interface ScoreCardProps {
    score: string
}
export const ScoreCard: React.FC<ScoreCardProps> = ({score}) =>{


    return( <CardLayout>
        <div className="scoreTopLeft">
            {score}
        </div>
        <div className="score">
            {score}
        </div>
        <div className="scoreBottomRight">
            {score}
        </div>
    </CardLayout>)
}

const CardLayout = styled.div`
    display: flex;
    width: 130px;
    height: 190px;
    border: 5px solid #33691E;
    border-radius: 5px;
    justify-content: center;
    align-items: center; 
    position: relative;
    background-color:#689F38 ;

    .score { 
        font-size: 36px;
        background-color: #33691E;
        width: 60px;
        height: 60px;
        line-height: 60px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid white;

    }

    .scoreTopLeft {
        position: absolute;
        left: 10px;
        top: 10px;
    }

    .scoreBottomRight {
        position: absolute;
        right: 10px;
        bottom: 10px;
    }
`;