import { Score } from "./Score";
import styled from "styled-components";
const Scores = ({ scores, onUpdateScore, onCustomInput, customInput }) => {
  const scoresList = scores.map((score) => (
    <Score score={score} onClickScore={onUpdateScore} />
  ));

  return (
    <ScoresLayout>
      {scoresList}
      <div className="custom">
        <CustomInput
          placeholder="Enter custom input"
          value={customInput}
          onChange={onCustomInput}
          type="text"
        ></CustomInput>
      </div>
    </ScoresLayout>
  );
};

const CustomInput = styled.input`
  background-color: unset;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-top: none;
  border-left: none;
  border-right: none;
  flex-basis: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  color: white;
`;

const ScoresLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.12);
  padding: 10px;
  border-radius: 10px;
  margin: 10px;

  .custom {
    flex-basis: 100%;
  }
  .score {
    flex-basis: 33%;
  }
`;

export default Scores;
