
import { useEffect, useState } from 'react';
import { onValue} from "firebase/database";
import {observeScores} from "../services/firebase";

function useScores() {
    const [scores, setScores] = useState([]);
    console.log("Scoess")

    useEffect(()=> {
        const ref = observeScores();
            const unsub = onValue(ref, (snapshot) => {
                const data = snapshot.val();
                console.log(data);
                if(!data){
                    setScores([])
                } else {
                    setScores(data);
                }
            });

            return () => {unsub()}
    }, [])

    return scores
}

export {useScores};