import {IUser} from "./auth";
import React, {useState} from "react";

interface IScoreContext{
    users: IUser[],
}
interface Props {
    children: React.ReactNode;
}
const ScoreContext = React.createContext<IScoreContext>({
    users: [],
});

export const ScoreProvider : React.FC<Props> = (props) => {
    const [userScoreState] = useState([]);


    return <ScoreContext.Provider value={{users: userScoreState}}>{props.children}</ScoreContext.Provider>
}