import React from "react";
import styled from "styled-components";

interface ScoreProps {
  score: string;
  onClickScore: (score: string) => {};
}
export const Score: React.FC<ScoreProps> = ({
  score,
  onClickScore,
}: ScoreProps) => {
  const click = () => {
    onClickScore(score);
  };
  return (
    <div className="score">
      <ScoreButton onClick={click}>{score}</ScoreButton>
    </div>
  );
};

const ScoreButton = styled.button`
  background-color: unset;
  border: none;
  font-size: 35px;
  color: #fff;

  &:hover {
    color: rgba(255, 255, 255, 0.08);
  }
`;
