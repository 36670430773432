import {
  clearScores,
  observeSettings,
  toggleDisplay,
  updateName,
  writeScore,
} from "./services/firebase";

import { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import "./App.css";
import { ScoreTable } from "./components/ScoreTable";
import { ScoreProvider } from "./context/score";
import { useScores } from "./hooks/useScores";
import { onValue } from "firebase/database";
import Name from "./components/Name";
import Scores from "./components/Scores";
import { Selection } from "./components/Selection";
import styled from "styled-components";
import ReactCanvasConfetti from "react-canvas-confetti";


const canvasStyles : CSSProperties= {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0
};
function App({ user }) {
  const [showScores, setShowScores] = useState(false);
  const [customInput, setCustomInput] = useState("");

  console.log(user);
  const scores = ["?", "0", "1", "2", "3", "5", "8", "13", "21"];

  const [name, setName] = useState(user.displayName || "Your Name");
  const s = useScores();

  const refAnimationInstance = useRef<any>(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);
  
  const ss = useRef(s);

  useEffect(() => {
    ss.current = s
  },[s]);

  useEffect(() => {
    const ref = observeSettings();
    const unsub = onValue(ref, (snapshot) => {
      const data = snapshot.val();
      setShowScores(data);

      if(data){
        let firstScore;
        for (let [, value] of Object.entries(ss.current)) {
          if(value["score"]){
            firstScore = value["score"];
          }
        }

        console.log(ss.current);
        if(Object.values(ss.current).length > 1 && Object.values(ss.current).every(score => firstScore != null && ((score as any).score == null || ((score as any).score && (score as any).score === firstScore)))){
          fire();
        }
      }
    
    });
    return () => {
      unsub();
    };
  }, [fire]);

  const onUpdateScore = async (score: string) => {
    setCustomInput("");
    await writeScore(score, user?.uid);
  };

  useEffect(() => {
    const delayDebounce = setTimeout(() => {
      if (user) {
        updateName(user.uid, name);
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [name, user]);

  const currentScore =
    s[user.uid] && (s[user.uid] as any).score
      ? (s[user.uid] as any).score
      : null;

  const onClearHandler = () => {
    clearScores();
    setCustomInput("");
    console.log("cleared");
  };



  const onRevealHandler = () => {
    console.log("revealed");
    toggleDisplay(!showScores);
  };

  const [runningContext, setRunningContext] = useState<null | String>(null);
  useEffect(() => {
    async function configureSdk() {
      try {
        const context = await (window as any).zoomSdk.getRunningContext();
        setRunningContext(context["context"]);
      } catch (error) {
        console.log(error);
        setRunningContext("inMeeting"); //for development
      }
    }
    configureSdk();
  }, []);

  const onCustomInput = async (e) => {
    setCustomInput(e.target.value);
    await writeScore(e.target.value, user?.uid);
  };

  console.log(s);

  const Estimate = (
    <ScoreProvider>
      <Name name={name} setName={setName}></Name>
      <Selection score={currentScore}></Selection>
      <div className="box">
        <ScoreTable show={showScores} scores={s} />
      </div>
      <Scores
        scores={scores}
        onUpdateScore={onUpdateScore}
        onCustomInput={onCustomInput}
        customInput={customInput}
      ></Scores>
      {showScores && <ViewResultsButton onClick={onRevealHandler}>
      Hide Results

      </ViewResultsButton>
      }
      {!showScores && <ViewResultsButton onClick={onRevealHandler}>
        View Results
      </ViewResultsButton>
      }
      <ViewResultsButton onClick={onClearHandler}>
        Start new Estimate
      </ViewResultsButton>
    </ScoreProvider>
  );

  return (
    <div className="container text-center p-2">
      {runningContext === "inMeeting" && Estimate}

      {/* {runningContext === "inMeeting" && Results} */}

      {runningContext && runningContext !== "inMeeting" && (
        <p style={{ backgroundColor: "red" }}>App only runs inside a meeting</p>
      )}
            <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />

    </div>
  );
}

const ViewResultsButton = styled.button`
  background-color: #33691e;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  font-size: 25px;
  padding: 5px 15px;
  margin-right: 10px;
  margin-top: 5px;
  border: none;

  &:hover {
    color: rgba(255, 255, 255, 0.08);
  }
`;

export default App;
