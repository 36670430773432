import React from "react";

export interface UserScore {
  display: string;
  score: string;
}

interface ScoreTableProps {
  scores: any;
  show: boolean;
}

export const ScoreTable: React.FC<ScoreTableProps> = ({ scores, show }) => {
  let tableData;

  if (show) {
    tableData = Object.entries(scores).map((us) => (
      <tr>
        <td>{(us as any)[1].display}</td>
        <td>{(us as any)[1].score}</td>
      </tr>
    ));
  } else {
    tableData = Object.entries(scores).map((us) => (
      <tr>
        <td>{(us as any)[1].display}</td>
        <td>{(us as any)[1].score && "*"}</td>
      </tr>
    ));
  }
  return (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <table style={{ width: "100%" }}>
        <tbody>
          <tr>
            <th>Name</th>
            <th>Score</th>
          </tr>
          {tableData}
        </tbody>
      </table>
    </div>
  );
};
